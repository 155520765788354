export * from './about'
export * from './careers'
export * from './error'
export * from './fgts'
export * from './home'
export * from './intranet'
export * from './loan-fgts'
export * from './loan-payroll'
export * from './loan-personal'
export * from './locations'
export * from './partners'
export * from './privacy-agreement'
export * from './submission'
export * from './terms-agreement'
