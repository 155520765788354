export { default as About } from './about/about'
export { default as Careers } from './careers/careers'
export { default as Error } from './error/error'
export { default as Fgts } from './fgts/fgts'
export { default as Home } from './home/home'
export { default as Intranet } from './intranet/intranet'
export { default as LoanFGTS } from './loan-fgts/loan-fgts'
export { default as LoanPayroll } from './loan-payroll/loan-payroll'
export { default as LoanPersonal } from './loan-personal/loan-personal'
export { default as Locations } from './locations/locations'
export { default as Partners } from './partners/partners'
export { default as PrivacyAgreement } from './privacy-agreement/privacy-agreement'
export { default as Submission } from './submission/submission'
export { default as TermsAgreement } from './terms-agreement/terms-agreement'
